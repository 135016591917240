<template>
  <CreatePin />
</template>
<script>
  import CreatePin from "@/components/ZillaCards/CreatePin";
  export default {
    components: {
      CreatePin,
    },
  };
</script>
