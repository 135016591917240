<template>
  <div class="main-container pt-6">
    <BackNav @click="$router.go(-1)" />
    <div>
      <h1 class="text-lg font-bold">Create pin</h1>
      <p class="mt-2 text-sm text-grey">
        Set a four digit pin for your card. This is the pin you’ll be using to
        perform card transactions.
      </p>
      <form action="" class="mt-4">
        <Input
          width="w-full"
          type="password"
          placeholder="Enter your 4 digit pin"
          errorText="Pin must contain 4 digits"
          v-model="pin"
          :validation="rules.pin"
          revealPassword
          autoComplete="new-password"
          number
          maxlength="4"
        />
        <Input
          id="confim-password"
          width="w-full"
          class="mt-6"
          type="password"
          placeholder="Confirm pin"
          v-model="confirmPin"
          errorText="Pins do not match"
          :validation="pin === confirmPin"
          revealPassword
          autoComplete="new-password"
          number
          maxlength="4"
        />
        <Button
          text="Create pin"
          width="w-full"
          class="mt-12"
          @click="setCardPin()"
          :disabled="disableButton"
        />
      </form>
    </div>
  </div>
</template>
<script>
import { Input } from "@/UI/Input";
import { Button } from "@/UI/Button";
import { BackNav } from "@/UI/Navigation";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    Input,
    Button,
    BackNav,
  },
  data() {
    return {
      pin: "",
      confirmPin: "",
    };
  },
  computed: {
    rules() {
      return {
        pin: this.pin.length === 4,
        confirm_password: this.pin === this.confirmPin,
      };
    },
    disableButton() {
      return Object.values(this.rules).includes(false);
    },
    ...mapState({
      card: (state) => state?.card?.card,
    }),
  },
  methods: {
    ...mapActions("card", ["updateCardPin", "updateCardPermanentAddress"]),
    ...mapActions("address", ["getPrimaryAddress"]),

    setCardPin() {
      this.updateCardPin(this.pin);
      if (this.card.type === "PHYSICAL") {
        this.$router.push("/drawer/card/address");
      } else {
        this.$router.push("/drawer/card/payment");
      }
    },

    setPermanentAddress() {
      this.getPrimaryAddress()
        .then((res) => {
          this.saveFullAddress(res.data.data);
          this.showToast({
            display: true,
            icon: "success",
            description: "Address added successfully",
          });
          this.updateCardDeliveryAddress(res.data.data.id);
          this.$router.push("/drawer/card/payment");
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.main-container {
  max-width: 36.563rem;
  margin: auto;
}
</style>

