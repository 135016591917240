var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container pt-6" },
    [
      _c("BackNav", {
        on: {
          click: function ($event) {
            return _vm.$router.go(-1)
          },
        },
      }),
      _c("div", [
        _c("h1", { staticClass: "text-lg font-bold" }, [_vm._v("Create pin")]),
        _c("p", { staticClass: "mt-2 text-sm text-grey" }, [
          _vm._v(
            " Set a four digit pin for your card. This is the pin you’ll be using to perform card transactions. "
          ),
        ]),
        _c(
          "form",
          { staticClass: "mt-4", attrs: { action: "" } },
          [
            _c("Input", {
              attrs: {
                width: "w-full",
                type: "password",
                placeholder: "Enter your 4 digit pin",
                errorText: "Pin must contain 4 digits",
                validation: _vm.rules.pin,
                revealPassword: "",
                autoComplete: "new-password",
                number: "",
                maxlength: "4",
              },
              model: {
                value: _vm.pin,
                callback: function ($$v) {
                  _vm.pin = $$v
                },
                expression: "pin",
              },
            }),
            _c("Input", {
              staticClass: "mt-6",
              attrs: {
                id: "confim-password",
                width: "w-full",
                type: "password",
                placeholder: "Confirm pin",
                errorText: "Pins do not match",
                validation: _vm.pin === _vm.confirmPin,
                revealPassword: "",
                autoComplete: "new-password",
                number: "",
                maxlength: "4",
              },
              model: {
                value: _vm.confirmPin,
                callback: function ($$v) {
                  _vm.confirmPin = $$v
                },
                expression: "confirmPin",
              },
            }),
            _c("Button", {
              staticClass: "mt-12",
              attrs: {
                text: "Create pin",
                width: "w-full",
                disabled: _vm.disableButton,
              },
              on: {
                click: function ($event) {
                  return _vm.setCardPin()
                },
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }